import React from "react";
import Carousel from "../../components/editorial/CarouselVideo";
import Table from "../../components/editorial/Table";
import MobileContent from "../../components/editorial/MobileContent";
import data from '../../../queries/data/mobile';
import Layout from "../../UI/Layout";
import scrollDown from '../../images/arrow-black.svg'
import '../../scss/mobile.scss';
import useLS from "../../hooks/useLS";

const MobilePage = () => {
  
    const LSData = useLS();

  const carousel = {
    mainCarousel : [
      ...data.data.wp.mobile.options
    ],
  }
  const tableData = data.data.wp.mobile.compare;
  const dataHeader = data.data.wp.mobile.dataHeader;

  const mainData = carousel.mainCarousel[2];

  const links = {
    prev: "/mobile/watch",
    next: "/mobile/swap"
  }


  return (
    <Layout className="Editorial mobilePage" dataHeader={dataHeader}>
        <MobileContent data={mainData} storeID={LSData.storeID} links={links} />
        
        <div className="scrollDownCont">
            <div className="scrollDown">
                <img src={scrollDown} />
                <h6>Scroll down to compare with other Networks</h6>
            </div>
        </div>
        
        <Table tableData={tableData}/>
    </Layout>
  )
}

export default MobilePage;